import React from 'react'
import CircleComponent from './CircleComponent'
import './CircleComponent.css';
import { motion } from "framer-motion";

export default function ProcessTimeline() {

    const processSteps = [

        {
            index:1,
            title:"MEET",
            description1:"We understand your",
            description2:"organizations needs"
        },
        {
            index:2,
            title:"AGREE",
            description1:"We set price/session, total",
            description2:"value and platform access"
        },
        {
            index:3,
            title:"USE",
            description1:"Employees receive access",
            description2:"to the platform"
        },
        {
            index:4,
            title:"EVALUATE",
            description1:"We track weekly usage",
            description2:"and monitor impact"
        }
        

    ]

    const containerVariant = {
        offScreen: {},
        onScreen: {
          transition: {
            staggerChildren: 0.1,
          },
        },
      };

  return (
    <div
     className="timeline-container">
        <span style={{color:"#FFFFFF",marginBottom:"1.5rem"}}>A fast and streamlined process</span>

    <motion.div 
     initial={{ opacity: 0 }}
     whileInView={{ opacity: 1 }}
    className='sub-container'>

        {
            processSteps.map((step)=>{

                return <CircleComponent key={step.index} index={step.index} title={step.title} description1={step.description1} description2={step.description2}  displayLine={step.index!=4} />

            })
        }

      </motion.div>
    </div>
  )
}
