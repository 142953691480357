import React from "react";
import { motion } from "framer-motion";
import "./Information.css";
import imageOne from "../../assets/information/image1.png";
import rectangle from "../../assets/information/rectangle-graphic.png";
import rectanglePhone from "../../assets/information/phone_shaped_rectangle.png";
import { Link } from "react-router-dom";
import infoImage1 from "../../assets/information/info-sec-1.png";
import infoImage2 from "../../assets/information/info-sec-2.png";

const ImageAndText = ({ sourceImg, title, desc, imgOnLeft }) => {
  const cardVariants = {
    offScreen: {
      y: "100px",
      opacity: 0,
    },
    onScreen: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.8,
      },
    },
  };

  return (
    <motion.div
      className={`${
        imgOnLeft
          ? "information-imgOnLeft"
          : "information-imgOnRight justify-end"
      } information-box`}
      initial="offScreen"
      whileInView="onScreen"
      viewport={{ once: true, amount: 0.9 }}
    >
      <motion.img
        src={imageOne}
        alt="curomates-info"
        className="information-imgOne"
        variants={cardVariants}
      />
      <motion.div className="information-text-wrapper" variants={cardVariants}>
        <h1>{title}</h1>
        <h4 className="information-description">{desc}</h4>
      </motion.div>
    </motion.div>
  );
};

export default function Information() {
  const descOne =
    "Have free private chats with empathetic listeners, find meditation sessions, and much more.";

  const cardVariants = {
    offScreen: {
      y: "100px",
      opacity: 0,
    },
    onScreen: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 1,
      },
    },
  };

  return (
    <div className="information-container">
      
      {/* --------------------DESKTOP PART------------------------------ */}
      <div className="desktop">
        {/* LEFT BOX */}
        <motion.div
          className="box leftBox"
          initial="offScreen"
          whileInView="onScreen"
          viewport={{ once: true, amount: 0.9 }}
        >
          <motion.img
            src={infoImage1}
            width={"50%"}
            alt="information"
            variants={cardVariants}
            className="left-box-img"
          />
          <motion.div className="textBox" variants={cardVariants}>
            <motion.h1>Say hi to a happier you</motion.h1>
            <motion.summary variants={cardVariants}>
            Curomates allows you to find relevant mental health communities, 
            have free private chats with empathetic listeners, 
            find psychologists, try guided meditation, 
            use diagnosis or monitoring tools, and much more.
            </motion.summary>
          </motion.div>
        </motion.div>
        {/* RIGHT BOX */}
        <motion.div
          className="box rightBox"
          initial="offScreen"
          whileInView="onScreen"
          viewport={{ once: true, amount: 0.9 }}
        >
          <motion.div className="textBox" variants={cardVariants}>
            <motion.h1>Made for everyone</motion.h1>
            <motion.summary variants={cardVariants}>
            Built on principles of compassion, inclusivity, and innovation, 
            Curomates offers personalized support tailored to your needs. 
            Take control of your mental health with Curomates and discover a path to greater well-being.
            </motion.summary>
          </motion.div>
          <motion.img
            src={infoImage2}
            width={"40%"}
            alt="information"
            variants={cardVariants}
          />
        </motion.div>{" "}

        <div className="information">
        {/* <motion.div
          className="main-info"
          initial="offScreen"
          whileInView="onScreen"
          viewport={{ once: true, amount: 0.9 }}
        >
          <img src={rectangle} alt="" className="rectangleImg" />
          <motion.summary
            className="main-descriptionOne"
            variants={cardVariants}
          >
            Have free private chats with empathetic listeners, find meditation
            sessions, and much more.
          </motion.summary>
          <motion.img
            src={rectanglePhone}
            alt=""
            className="rectanglePhone"
            variants={cardVariants}
          />

          <motion.summary className="descriptionTwo" variants={cardVariants}>
            Have free private chats with empathetic listeners, find meditation
            sessions, and much more.
          </motion.summary>
        </motion.div> */}
        <Link to="/blogs" style={{ textDecoration: "none", color: "black" }}>
          <motion.div
            initial="offScreen"
            whileInView="onScreen"
            viewport={{ once: true, amount: 0.9 }}
          >
            <motion.div className="info-blog-card" variants={cardVariants}>
              <motion.div className="info-blog-card-img">
                <img 
                  src="https://firebasestorage.googleapis.com/v0/b/curomates-test.appspot.com/o/aid-13%2Fburned-out.jpg?alt=media&token=32207d53-819d-4750-91b9-8b9119f2ce3e"
                  className="info-blog-image"
                />
              </motion.div>
              <h1>Check out Our Blog!</h1>
            </motion.div>
          </motion.div>
        </Link>
      </div>
      
      </div>
    </div>
  );
}
