import expoDubai from "../../assets/featured/expo-dubai.png";
import iit from "../../assets/featured/iit.png";
import inc from "../../assets/featured/inc.png";
import india from "../../assets/featured/india.png";
import "./Featured.css";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";


export default function Featured() {
  const [ref, inView] = useInView({triggerOnce:true, threshold:1});
  const featuredImagesVariant ={
    offScreen: {
      y: "100px",
      opacity: 0,
    },
    onScreen: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.8,
      },
    },
  }

  const containerVariant = {
    offScreen: {},
    onScreen: {
        transition: {
            staggerChildren: 0.3
        }
    }
};

  return (
    <motion.div className="featured" ref={ref} initial="offScreen" animate={inView ? "onScreen" : "offScreen"} >
      <motion.h1 className="center" variants={featuredImagesVariant}>As featured on</motion.h1>
      <motion.div className="featured-icons" variants={containerVariant}>
        <motion.img
          src={iit}
          alt="curomates-iit"
          className="featured-iit center"
          variants={featuredImagesVariant}
        />
        <motion.img
          src={expoDubai}
          alt="curomates-expo-dubai"
          className="featured-expo-dubai center"
          variants={featuredImagesVariant}
        />
        <motion.img
          src={india}
          alt="curomates-india"
          className="featured-india center"
          variants={featuredImagesVariant}
        />
        <motion.img
          src={inc}
          alt="curomates-inc"
          className="featured-inc center"
          variants={featuredImagesVariant}
        />
      </motion.div>
    </motion.div>
  );
}
