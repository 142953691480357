import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
import TermsOfServicePage from "./pages/TermsOfServicePage";
import Faq from "./components/home/Faq";
import AboutUs from "./pages/about-us/AboutUs";
import BlogsPage from "./pages/blogs/BlogsPage";
import BlogPost from "./components/blogs/BlogPost";
import CategoriesPage from "./pages/blogs/CategoriesPage";
import ScrollToTop from "./components/common/ScrollToTop";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import PaymentComplete from "./pages/PaymentComplete";
import CorporateOfferingsPage from "./pages/CorporateOfferingsPage";
import RefundPolicy from "./pages/RefundPolicy";
import DeleteAccountPage from "./pages/DeleteAccountPage";

function App() {
  return (
    <div className="App">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div className="top-banner">
        <a
          href="https://play.google.com/store/apps/details?id=com.curomates.mobile"
          target="_blank"
          rel="noopener noreferrer"
        >
          Download the app today
        </a>
      </div>
      <Header />
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/corporate" element={<CorporateOfferingsPage />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/terms-of-service" element={<TermsOfServicePage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/blogs" element={<BlogsPage />} />
          <Route path="/blogs/:category" element={<CategoriesPage />} />
          <Route path="/blogs/posts/:blogTitle" element={<BlogPost />} />
          <Route path="/paymentComplete" element={<PaymentComplete />} />
          <Route path="/delete-account" element={<DeleteAccountPage/>} />

        </Routes>
      </ScrollToTop>
      <Footer />
    </div>
  );
}

export default App;
