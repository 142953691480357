import React from "react";
import "./Faq.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { faqData } from "../../faqData";

const Accordion = ({ question, answer }) => {
  return (
    <details>
      <summary>
        <span className="faq-summary-title">{question}</span>
        <div className="faq-summary-down">
          <FontAwesomeIcon
            icon={faAngleDown}
            className="faq-summary-arrow faq-summary-down-icon"
          />
        </div>
        <div className="faq-summary-arrow faq-summary-up">
          <FontAwesomeIcon icon={faAngleUp} />
        </div>
      </summary>

      <h4 className="faq-summary-content">{answer}</h4>
    </details>
  );
};

export default function Faq() {
  return (
    <section className="faq-container">
      <section className="faq">
        <h1 className="faq-heading">
          Frequently<span> Asked Questions</span>
        </h1>
        <div className="faq-accordion">
          {faqData.map((item, index) => (
            <Accordion
              key={index}
              question={item.question}
              answer={item.answer}
            />
          ))}
        </div>
      </section>
    </section>
  );
}
