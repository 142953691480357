export const faqData = [
  {
    question: "What is Curomates?",
    answer: "Curomates is a community-powered application that helps you improve your mental health. Venting about problems really helps, so you can talk to volunteer listeners anonymously, anytime. You can also track your mental health, listen to relaxing music, guided meditation routines, and much more. Come and be a part of a wholesome and inclusive community!"
  },
  {
    question: "Do I get therapy at Curomates?",
    answer: "Curomates provides a platform for anonymous conversations with volunteer listeners who are there to lend an ear and offer support. However, Curomates is not a substitute for professional therapy. If you're seeking therapy, it's best to consult a licensed mental health professional."
  },
  {
    question: "Can I get a job at Curomates?",
    answer: "Curomates relies on volunteer listeners to provide support to the community. It does not offer job positions. However, you can contribute as a volunteer listener if you're interested in helping others and making a positive impact on people's lives."
  },
  {
    question: "How much does a listening session cost?",
    answer: "Curomates is a free platform for users seeking emotional support. The listening sessions provided by volunteer listeners are free of charge. Curomates aims to make mental health support accessible to everyone."
  },
  {
    question: "Who has created this application?",
    answer: "Curomates was created by a team of developers and mental health enthusiasts who are passionate about providing a safe and supportive community for individuals to improve their mental well-being. The specific names of the creators are not disclosed."
  },
  {
    question: "Do you have an iOS application?",
    answer: "Curomates currently offers an Android application for users to access the platform. As of now, there is no iOS application available. However, you can access Curomates through its website on any device with a web browser."
  }
];