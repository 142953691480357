import React from 'react'
import Hero from '../components/home/Hero'
import Featured from '../components/home/Featured'
import Information from '../components/home/Information'
import ImageRedirect from '../components/home/ImageRedirect'
import Faq from '../components/home/Faq'
import QuickLinks from '../components/home/QuickLinks'
import ImageBanners from '../components/home/ImageBanners'


export default function HomePage() {
  return (

    <div className="home">
        <Hero/>
        <Featured/>
        <Information/>
        <ImageBanners/>
        <Faq/>
        <QuickLinks/>
    </div>

  )
}
